// /* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable no-unused-vars */
// import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import segmentPlugin from '@analytics/segment';
// import Analytics from 'analytics';
// import { setAnalytics } from 'slices/segments/slice';
// import { useSelector } from 'react-redux';

// const SetUpAnalytics = () => {
//   const dispatch = useDispatch();
//   const authToken = useSelector((state) => state.auth.authToken);
//   const setup = () => {
//     const analytics = Analytics({
//       app: 'enquirybox',
//       plugins: [
//         segmentPlugin({
//           writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY
//         })
//       ]
//     });
//     if (analytics) {
//       dispatch(setAnalytics(analytics));
//     }
//   };

//   useEffect(() => {
//     setup();
//   }, [authToken]);

//   return <></>;
// };

// export default SetUpAnalytics;

import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

export const analytics = Analytics({
  app: 'enquirybox',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY
    })
  ]
});
