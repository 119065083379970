import logo from 'assets/images/eb-logo.svg';

const LogoLoader = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <img src={logo} alt="eb" className="initial-logo-flash" />
    </div>
  );
};

export default LogoLoader;
